// @flow strict
import React, { useEffect } from 'react';
import styles from './Content.module.scss';

type Props = {
  body: string,
  title?: string
};

const Content = ({ body, title, audioSrc }: Props) => {
  const episodeId = audioSrc.split('buzzsprout-player-')[1].split('&player=small')[0];

  useEffect(() => {
    const script = document.createElement('script');

    script.src = audioSrc;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
  <div className={styles['content']}>
    {title && <h1 className={styles['content__title']}>{title}</h1>}
    <div id={`buzzsprout-player-${episodeId}`} className={styles['content__player']} ></div>
    <div className={styles['content__body']} dangerouslySetInnerHTML={{ __html: body }} />
  </div>
  );
};

export default Content;
